import React from "react";

import { Container, Row, Col, Accordion, Card } from 'react-bootstrap';

import { Layout } from '../components/layout';
import { Masthead } from '../components/masthead';
import Section from '../components/section'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee } from '@fortawesome/free-solid-svg-icons'


import "../style/global.scss"

import mastheadImage from '../images/masthead.jpg'


export default function Index() {
  return (
    <Layout title="Home | J Squared Design Services">
      <Masthead>
        <Masthead.Background>
          <img className="w-100 h-100" style={{ 'objectFit': 'cover' }} src={mastheadImage} alt="" />
        </Masthead.Background>
      </Masthead>
      <Section>
        <Container >
          <h1 className="text-center">Our Mission</h1>
          <p className="text-center">Our goal is to help companies fight engineering bottle necks with high quality engineering solutions that exceed expectations. With more than nine years of individual design experience, we stand ready to serve all of your engineering needs. With the understanding that lead time is second only to quality, we work to minimize the time a job will spend in engineering back log and maximize the time your shop has to build tooling.</p>
        </Container>
      </Section>

      <Section>
        <Container>
          <h1 className="text-center">How we operate</h1>
          <Row className="justify-content-md-center">
            <Col>
              <Container>
                J Squared Design Services understands that, in addition to quality engineering, there's a level of company culture that goes into each design. It may not appear important to everyone, but we understand that "how we do things" is an important part of how you stay competitive in the market place. We strive to ensure our designs match not only a high engineering standard but also match the company culture and all the things that keep your business competitive.
              </Container>
            </Col>
          </Row>
        </Container>
      </Section>
      <Section>
        <Container>
          <h1 className="text-center">Services</h1>
          <Row className="justify-content-md-center">
            <Col md="9">
              <Container>
                <Accordion>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="0">
                      Injection Molds
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>Our designers are experienced with all facets of an injection mold design, including hot runners, three plates, rack driven rotating cores, hydraulic actions, and mechanical slides.</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="1">
                      Blow Molds
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>A key component of designing a successful blow mold is also understanding the process behind it. Advising on proper blow ratios, flash depth and pinch geometry are all value added services that can be taken into consideration during the mold design process.</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="2">
                      Compression Molds
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>J Squared can support the full line of your compression molded product and works hard to match your manufacturing methods to ensure a smooth build that matches all of your requirements.</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="3">
                      Aerospace Tooling
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>J Squared Design Services has experience with most types of aerospace tooling on a detail and assembly level.</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="4">
                      Rotational Molded Parts
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="4">
                      <Card.Body></Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Accordion.Toggle as={Card.Header} eventKey="5">
                      Thermoforms
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey="5">
                      <Card.Body></Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </Container>
            </Col>
          </Row>
        </Container>
      </Section>
    </Layout>
  )
}
